import React from "react";
import {graphql} from "gatsby";

import "../sass/index.scss"
import App from './App';

const Home = props => {
    const data = props.data
    const homePage = data.wordpressAcfPages.acf;
    return (
            <App data={homePage}/>
    )
}

export default Home;


export const pageQuery = graphql`
  query {
  wordpressAcfPages {
    acf {
      title
      cards {
        comment
        author
        add_text
        cl_comment
      }
      violetBellTitle
      violetBellText
      diffProfTitle
      diffProfParts {
        title
        list {
          item
        }
        description
      }
      violetBellTitle_2
      violetBellText_2
      violetBellForm_2
      CarierPathTitle
      paths {
        pathTitle
      }
      testTitle
      test_desc
      questions {
        question
        answers {
          answer
        }
        question_id
      }
     
      paths {
        pathTitle
        points {
          placeName
          addInfo
        }
        wordpress_id
      }
      students_title
      kursanci {
        name
        info
        who_is
        image {
          source_url
        }
        link
      }
      students_link
      footer_title
      footer_person_contact
      footer_socials {
        link
      }
    }
  }

}
    `